<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import formAdd from './components/form_add.vue';
import People from './components/people.vue';
import Executor from './components/executor.vue';

formCreate.component('formAdd', formAdd);
formCreate.component('People', People);
formCreate.component('Executor', Executor);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      buttons: {
        submit: false,
      },
      // 执行对象
      objectDetail: [],
    };
  },
  async created() {
    if (this.formConfig.type === 'add') {
      this.addForm();
    } else {
      this.detailForm();
    }
  },

  methods: {
    // 新增表单
    async addForm() {
      const rule = await this.getFormRule('sfa_task_work_task_add');
      rule.forEach((item) => {
        const v = item;
        if (v.field === 'time') {
          v.props = {
            ...v.props,
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < Date.now();
              },
            },
          };
        }
        if (v.field === 'file') {
          v.props = {
            ...v.props,
            showFileList: true,
            uploadType: 'file',
            listType: 'text',
            fileMsg: '只能上png/jpg/jpeg文件',
            accept: '.png,.jpg,.jpeg',
          };
        } else if (v.field === 'people') {
          v.on = {
            ...v.on,
            input: (val) => {
              // 获取单个字段rule
              const executor = this.getRule('executor');
              executor.props = {
                ...executor.props,
                peopleList: val,
              };
            },
          };
        }
      });
      this.buttons.submit = true;
      this.rule = rule;
      this.reload(this.rule);
    },
    // 详情表单
    async detailForm() {
      // 执行对象明细
      const objectRes = await request.post('/sfa/sfaWorkTaskObjectController/list', {
        taskId: this.formConfig.taskId,
      });
      if (objectRes.success) {
        this.objectDetail = objectRes.result.data;
      }
      const rule = await this.getFormRule('sfa_task_work_task_detail');
      const newRule = [];
      rule.forEach((item) => {
        const v = item;
        if (v.field === 'form_add') {
          newRule.push({
            type: 'formAdd',
            field: 'form_add',
            title: '',
            value: {},
            props: {
              type: this.formConfig.type,
              objectDetail: this.objectDetail, // 执行对象数据
            },
          });
        }
      });
      this.buttons.submit = false;
      this.rule = newRule;
      this.reload(this.rule);
      request
        .get('/sfa/sfaworktaskreceve/querySfaWorkTaskReport', {
          id: this.formConfig.id,
        })
        .then((res) => {
          if (res.success) {
            // 任务详情
            this.setValue({ form_add: res.result });
          }
        });
    },
    // 提交
    submit() {
      const formData = this.getFormData();
      if (!formData) return false;
      // 照片信息

      const pictures = [];
      formData.file.forEach((item) => {
        pictures.push({
          picPath: item.urlPath,
          picUrl: item.urlPathPrefix,

        });
      });
      // 人员信息
      const sfaStaffMessageReqVos = [];
      formData.people.forEach((item) => {
        sfaStaffMessageReqVos.push({
          receverCode: item.userName, // 接受人账号
          receverName: item.fullName, // 接受人姓名
          receverOrgCode: item.orgCode, // 组织编码
          receverOrgName: item.orgName, // 组织名称
          receverPosCode: item.positionCode, // 职位编码
          receverPosName: item.parentName, // 职位名称
        });
      });
      // 执行对象
      const taskObjects = [];
      if (formData.executor && formData.executor.length > 0) {
        let customerType = null;
        formData.executor.forEach((item) => {
          if ('terminalCode' in item) {
            customerType = 0;
          } else {
            customerType = 1;
          }
          taskObjects.push({
            customerCode: item.clientCode, // 客户编码
            customerName: item.clientName, // 客户名称
            // customerType: item.clientTypeName, // 客户类型
            customerType,
          });
        });
      }
      const requestUrl = '/sfa/sfaworktaskrelease/save';
      const requestParam = {
        taskTitle: formData.title,
        endDate: formData.time,
        content: formData.content,
        pictures, // 文件
        sfaStaffMessageReqVos, // 人员
        taskObjects, // 执行对象
      };
      request.post(requestUrl, requestParam)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.message);
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
    },
  },
};
</script>
