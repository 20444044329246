<template>
  <div>
    <el-row class="margin-bottom-10" v-if="!disabled">
      <el-button type="primary" @click="addRows">添加</el-button>
      <el-button plain @click="deleteRows">删除</el-button>
    </el-row>
    <vxe-table :data="list" ref="xTable" @checkbox-all="({records}) => selectEvent(records)" @checkbox-change="({records}) => selectEvent(records)">
      <vxe-table-column type="checkbox" width="50" v-if="!disabled"></vxe-table-column>
      <vxe-table-column title="组织名称" field="orgName" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column title="职位名称" field="positionName" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column title="人员名称" field="fullName" min-width="100" show-overflow></vxe-table-column>
    </vxe-table>

    <!-- 添加控件弹窗 -->
    <Modal :modalConfig="modalConfig" @onClose="closeModal">
      <el-row>
        <el-col :span="8">
          <el-select
            v-model="modalConfig.searchData"
            filterable
            remote
            reserve-keyword
            placeholder="请输入人员姓名模糊搜索"
            :remote-method="remoteMethod"
            @change="getChange($event)"
            @clear="getClear"
            :loading="modalConfig.loading"
            clearable>
            <el-option
              v-for="item in modalConfig.options"
              :key="item.id"
              :label="(item.orgName || '') + '/' + (item.positionName || '') + '/' + (item.fullName || '')"
              :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <div class="table-body">
        <vxe-table border ref="xTable" :data="modalConfig.data" @checkbox-change="dialog_selectEvent" @checkbox-all="dialog_selectEvent">
          <vxe-table-column type="checkbox" width="50"></vxe-table-column>
          <vxe-table-column field="orgName" title="组织名称" min-width="100" show-overflow></vxe-table-column>
          <vxe-table-column field="positionName" title="职位名称" min-width="100" show-overflow></vxe-table-column>
          <vxe-table-column field="userName" title="人员编码" min-width="100" show-overflow></vxe-table-column>
          <vxe-table-column field="fullName" title="人员姓名" min-width="100" show-overflow></vxe-table-column>
          <vxe-table-column title="操作" min-width="100">
            <template v-slot="{rowIndex}">
              <el-button type="text" @click="dialog_delTable(rowIndex)">删除</el-button>
            </template>
          </vxe-table-column>
        </vxe-table>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="submit" size="small" icon="el-icon-check" >提交</el-button>
        <el-button type="danger"  @click="closeModal" size="small" icon="el-icon-close" >关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '../../../../../../../components/modal';
import request from '../../../../../../../utils/request';

export default {
  components: {
    Modal,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.list = val || [];
      },
    },
    list: {
      deep: true,
      immediate: true,
      handler(val) {
        this.modalConfig.checkRowKeys = [];
        if (val && Array.isArray(val)) {
          val.map((item) => {
            this.modalConfig.checkRowKeys.push(item.positionCode);
            return item;
          });
        }
      },
    },
  },
  data() {
    return {
      modalConfig: {
        title: '选择人员',
        width: '800px',
        visible: false,
        type: 'Modal',
        searchDate: '',
        loading: false,
        options: [], // 搜索列表
        data: [], // 列表数据
        checkRowKeys: [], // 默认选中的
        selection: [], // 选中的
      }, // 选择组织弹窗
      list: [], // 组织列表
      selectList: [], // 选择的列表
    };
  },
  created() {
    // this.remoteMethod();
  },
  methods: {
    /** @desc 顶部添加按钮 */
    addRows() {
      this.remoteMethod();
      this.modalConfig.visible = true;
    },
    /** @desc 关闭选择组织弹窗 */
    closeModal() {
      this.modalConfig.visible = false;
    },
    /** @desc 选择列表事件 */
    selectEvent(records) {
      this.selectList = records;
    },
    /** @desc 顶部删除控件按钮 */
    deleteRows(type, rowIndex) {
      // 删除选中的数据
      if (this.selectList && this.selectList.length > 0) {
        this.selectList.map((item) => {
          const index = this.list.findIndex((val) => val.positionCode === item.positionCode);
          if (index > -1) {
            this.list.splice(index, 1);
          }
          return item;
        });
        this.selectList = [];
        this.modalConfig.checkRowKeys = [];
      } else {
        this.$message.warning('请选择数据');
      }
      this.$forceUpdate();
      this.emitChange();
    },
    /** @desc 人员姓名远程搜索 */
    remoteMethod(val) {
      this.modalConfig.loading = true;
      const params = {
        enableStatus: '009',
        includeEmptyUserPosition: false,
        unionName: val,
      };
      request.post('/mdm/mdmPositionController/positionSelectList', params, { headers: { functionCode: 'select-position' } }).then((res) => {
        if (res.success) {
          this.modalConfig.options = res.result || [];
          this.modalConfig.loading = false;
        }
      });
    },
    /** @desc 搜索清楚 */
    getClear() {
      this.remoteMethod();
    },
    /** @desc 搜索选择 */
    getChange(e) {
      const optionsIndex = this.modalConfig.options.findIndex((val) => val.id === e);
      if (optionsIndex > -1) {
        const item = this.modalConfig.options[optionsIndex];
        const obj = this.modalConfig.data.find((x) => x.id === e);
        if (!obj) {
          this.modalConfig.data.push(item);
        }
      }
    },
    /** @desc 点击删除按钮 */
    dialog_delTable(index) {
      this.modalConfig.data.splice(index, 1);
    },
    /** @desc 选择事件 */
    dialog_selectEvent({ records }) {
      this.modalConfig.selection = records;
    },

    /** @desc 按钮确认事件 */
    submit() {
      if (this.modalConfig.selection.length === 0) return this.$message.warning('请至少选择一条人员数据！');
      /** @todo
       * 后端需要的字段是wspCode,wspName
       * parentCode 有parentCode顶级组织（当前及下级组织），没有就是当前组织
       * 直接所有赋值，不需要数据合并和去重，因为此处不存在分页（此时，可坚固，取消已勾选的数据，外部列表数据同时也改变）
      */
      this.list = this.modalConfig.selection;
      this.modalConfig.checkRowKeys = [];
      this.modalConfig.selection = [];
      this.modalConfig.visible = false;
      this.emitChange();
    },
    emitChange() {
      this.$emit('input', this.list || []);
    },
  },
};
</script>
<style lang="less" scoped>
  .margin-bottom-10{
    margin-bottom: 10px;
  }
  .right{
    text-align: right;
  }
  .table-body{
    margin-top: 15px;
  }
</style>
