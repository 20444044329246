var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _vm.type === "publishPicture" || _vm.type === "view"
            ? [
                _c("div", { staticClass: "title" }, [_vm._v("任务详情")]),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "flex", attrs: { label: "任务标题" } },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.taskTitle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "taskTitle", $$v)
                                },
                                expression: "value.taskTitle",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "flex", attrs: { label: "发布人" } },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.senderName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "senderName", $$v)
                                },
                                expression: "value.senderName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "flex", attrs: { label: "发布日期" } },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.releaseDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "releaseDate", $$v)
                                },
                                expression: "value.releaseDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "flex", attrs: { label: "任务内容" } },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", readonly: "" },
                              model: {
                                value: _vm.value.content,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "content", $$v)
                                },
                                expression: "value.content",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "flex", attrs: { label: "截止日期" } },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.endDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "endDate", $$v)
                                },
                                expression: "value.endDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: "任务总状态" },
                          },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.taskStatusDesc,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "taskStatusDesc", $$v)
                                },
                                expression: "value.taskStatusDesc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: "任务执行人" },
                          },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.receverName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "receverName", $$v)
                                },
                                expression: "value.receverName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticClass: "fileImg", attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: "任务发布照片" },
                          },
                          _vm._l(
                            _vm.value.sendTaskPicList,
                            function (item, index) {
                              return _c("el-image", {
                                key: index,
                                attrs: {
                                  src: item,
                                  lazy: "",
                                  "preview-src-list": [item],
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleClickItem.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.type === "executePicture" || _vm.type === "view"
            ? [
                _c("div", { staticClass: "title" }, [_vm._v("执行详情")]),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: "任务执行状态" },
                          },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.executeStatusDesc,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "executeStatusDesc", $$v)
                                },
                                expression: "value.executeStatusDesc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "flex", attrs: { label: "执行人" } },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.receverName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "receverName", $$v)
                                },
                                expression: "value.receverName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: "执行人账号" },
                          },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.receverCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "receverCode", $$v)
                                },
                                expression: "value.receverCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: "执行人所在区域" },
                          },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.receverOrgName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "receverOrgName", $$v)
                                },
                                expression: "value.receverOrgName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: "执行人所在办事处" },
                          },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.receverOrgName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "receverOrgName", $$v)
                                },
                                expression: "value.receverOrgName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "flex", attrs: { label: "执行日期" } },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.receveDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "receveDate", $$v)
                                },
                                expression: "value.receveDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: "任务执行定位" },
                          },
                          [
                            _c("el-input", {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.value.address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "address", $$v)
                                },
                                expression: "value.address",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: "任务执行总结" },
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea", readonly: "" },
                              model: {
                                value: _vm.value.summary,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "summary", $$v)
                                },
                                expression: "value.summary",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticClass: "fileImg", attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex",
                            attrs: { label: "任务执行照片" },
                          },
                          _vm._l(
                            _vm.value.executeTaskPicList,
                            function (item, index) {
                              return _c("el-image", {
                                key: index,
                                attrs: {
                                  src: item,
                                  lazy: "",
                                  "preview-src-list": [item],
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleClickItem.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.type === "view"
            ? [
                _c("div", { staticClass: "title" }, [_vm._v("执行对象")]),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "vxe-table",
                      { attrs: { data: _vm.objectDetail } },
                      [
                        _c("vxe-table-column", {
                          attrs: { field: "customerCode", title: "对象编码" },
                        }),
                        _c("vxe-table-column", {
                          attrs: { field: "customerName", title: "对象名称" },
                        }),
                        _c("vxe-table-column", {
                          attrs: { field: "customerType", title: "对象类型" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    row.customerType == 0
                                      ? _c("span", [_vm._v("终端")])
                                      : _vm._e(),
                                    row.customerType == 1
                                      ? _c("span", [_vm._v("经销商")])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            617197501
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }