<template>
  <div>
    <el-row class="margin-bottom-10" v-if="!disabled">
      <el-button type="primary" @click="addDelear">添加经销商</el-button>
      <el-button type="primary" @click="addTerminal">添加终端</el-button>
      <el-button plain @click="deleteRows">删除</el-button>
    </el-row>
    <vxe-table :data="list" ref="xTable" @checkbox-all="({records}) => selectEvent(records)" @checkbox-change="({records}) => selectEvent(records)">
      <vxe-table-column type="checkbox" width="50" v-if="!disabled"></vxe-table-column>
      <vxe-table-column title="客户编码" field="clientCode" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column title="客户名称" field="clientName" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column title="客户类型" field="clientTypeName" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column title="联系人" field="clientContacts" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column title="客户地址" field="clientAddress" min-width="100" show-overflow></vxe-table-column>
    </vxe-table>

    <!-- 添加控件弹窗 -->
    <Modal :modalConfig="modalConfig" @onClose="closeModal">
      <vxe-table
        ref="xTable"
        row-id="clientCode"
        :data="modalConfig.data"
        @checkbox-all="({records}) => dialog_selectEvent(records)"
        @checkbox-change="({records}) => dialog_selectEvent(records)"
        :checkbox-config="{checkRowKeys: modalConfig.checkRowKeys, reserve: true}">
        <vxe-table-column type="checkbox" width="50" v-if="!disabled"></vxe-table-column>
        <vxe-table-column title="客户编码" field="clientCode" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column title="客户名称" field="clientName" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column title="客户类型" field="clientTypeName" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column title="联系人" field="clientContacts" min-width="100" show-overflow></vxe-table-column>
        <vxe-table-column title="客户地址" field="clientAddress" min-width="100" show-overflow></vxe-table-column>
      </vxe-table>
      <div class="page-pageination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="modalConfig.tablePage.currentPage"
          :page-sizes="[5, 10, 15, 20, 50, 100, 150, 200]"
          :page-size="modalConfig.tablePage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="modalConfig.tablePage.totalResult"
        >
        </el-pagination>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="submit" size="small" icon="el-icon-check" >提交</el-button>
        <el-button type="danger"  @click="closeModal" size="small" icon="el-icon-close" >关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '../../../../../../../components/modal';
import request from '../../../../../../../utils/request';
import Storage from '../../../../../../../utils/storage';

const clientTypes = {
  1: '分销商',
  2: '终端',
  3: '经销商',
};
export default {
  components: {
    Modal,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    // 接受people data
    peopleList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.orgList = val || [];
      },
    },
    list: {
      deep: true,
      immediate: true,
      handler(val) {
        this.modalConfig.checkRowKeys = [];
        if (val && Array.isArray(val)) {
          val.map((item) => {
            this.modalConfig.checkRowKeys.push(item.clientCode);
            return item;
          });
        }
      },
    },
    peopleList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && Array.isArray(val)) {
          this.peopleList = val;
        }
      },
    },
  },
  data() {
    return {
      modalConfig: {
        title: '选择执行对象',
        width: '800px',
        visible: false,
        type: 'Modal',
        clientType: 'dalear',
        data: [], // 列表数据
        checkRowKeys: [], // 默认选中的
        selection: [], // 选中的
        tablePage: {
          currentPage: 1,
          pageSize: 10,
          totalResult: 0,
        },
      }, // 选择组织弹窗
      list: [], // 组织列表
      selectList: [], // 选择的列表
    };
  },
  created() {

  },
  methods: {
    /** @desc 顶部添加按钮 */
    addDelear() {
      this.modalConfig.data = [];
      this.modalConfig.clientType = 'delear';
      this.modalConfig.visible = true;
      this.dialog_getDelearList();
    },
    addTerminal() {
      this.modalConfig.data = [];
      this.modalConfig.clientType = 'terminal';
      this.modalConfig.visible = true;
      this.dialog_getTerminalList();
    },
    /** @desc 关闭选择组织弹窗 */
    closeModal() {
      this.modalConfig.visible = false;
    },
    /** @desc 选择列表事件 */
    selectEvent(records) {
      this.selectList = records;
    },
    /** @desc 顶部删除控件按钮 */
    deleteRows(type, rowIndex) {
      // 删除选中的数据
      if (this.selectList && this.selectList.length > 0) {
        this.selectList.map((item) => {
          const index = this.list.findIndex((val) => val.clientCode === item.clientCode);
          if (index > -1) {
            this.list.splice(index, 1);
          }
          return item;
        });
        this.selectList = [];
        this.modalConfig.checkRowKeys = [];
      } else {
        this.$message.warning('请选择数据');
      }
      this.$forceUpdate();
      this.emitChange();
    },
    /** @desc 获取经销商列表 */
    async dialog_getDelearList() {
      // console.log(this.peopleList);
      // 根据职位编码list查找经销商
      const positionCodeList = [];
      this.peopleList.forEach((item) => {
        positionCodeList.push(item.positionCode);
      });
      try {
        const params = {
          pageNum: this.modalConfig.tablePage.currentPage,
          pageSize: this.modalConfig.tablePage.pageSize,
          positionCodeList,
        };
        const res = await request.post('/mdm/mdmCustomerMsgController/listAndContactPage', params);
        const data = res ? res.result : [];
        if (data.data && data.data.length > 0) {
          this.modalConfig.data = data.data.map((item) => ({
            ...item,
            clientCode: item.customerCode,
            clientName: item.customerName,
            clientTypeName: item.customerTypeName,
            clientContacts: item.contactName,
            clientAddress: item.registeredAddress,
          }));
        }
        this.modalConfig.tablePage.totalResult = data.count;
      } catch (error) { console.log(error); }
    },
    /** @desc 获取经销终端 */
    async dialog_getTerminalList() {
      // 根据职位编码list查找经销商
      const positionCodeList = [];
      this.peopleList.forEach((item) => {
        positionCodeList.push(item.positionCode);
      });
      try {
        const params = {
          pageNum: this.modalConfig.tablePage.currentPage,
          pageSize: this.modalConfig.tablePage.pageSize,
          // positionCode: JSON.parse(Storage.l.get('userInfo')).positionCode,
          positionCodeList,
        };
        const res = await request.post('/mdm/mdmTerminalController/userTerminalContactPage', params);
        if (!res.success) return;
        const { data, count } = res.result;
        if (data && data.length > 0) {
          this.modalConfig.data = data.map((item) => ({
            ...item,
            clientCode: item.terminalCode,
            clientName: item.terminalName,
            clientTypeName: item.terminalTypeName,
            clientContacts: item.contactName,
            clientAddress: item.terminalAddress,
          }));
        }
        this.modalConfig.tablePage.totalResult = count;
      } catch (error) {
        this.$message.error(error);
      }
    },
    /** @desc 列表选中 */
    dialog_selectEvent(val) {
      this.modalConfig.selection = val;
    },
    /** @desc pageSize改变 */
    handleSizeChange(val) {
      this.modalConfig.tablePage.pageSize = val;
      if (this.modalConfig.clientType === 'delear') {
        this.dialog_getDelearList();
      } else {
        this.dialog_getTerminalList();
      }
    },
    /** @desc currentPage改变 */
    handleCurrentChange(val) {
      this.modalConfig.tablePage.currentPage = val;
      if (this.modalConfig.clientType === 'delear') {
        this.dialog_getDelearList();
      } else {
        this.dialog_getTerminalList();
      }
    },
    /**
     * @param {Array} array1 数组1
     * @param {Array} array2 数组2
     * @param {String} key 去重字段
     * @desc 两个数组去重
     * */
    uniqueArr(array1, array2, key) {
      const arraydata = array1.concat(array2);
      const hash = {};
      const newArr = arraydata.reduce((item, next) => {
        if (!hash[next[key]]) {
          hash[next[key]] = true;
          item.push(next);
        }
        return item;
      }, []);
      return newArr;
    },
    /** @desc 按钮确认事件 */
    submit() {
      if (this.modalConfig.selection.length === 0) return this.$message.warning('请至少选择一条执行对象数据！');
      /** @todo
       * 后端需要的字段是wspCode,wspName
       * parentCode 有parentCode顶级组织（当前及下级组织），没有就是当前组织
       * 直接所有赋值，不需要数据合并和去重，因为此处不存在分页（此时，可坚固，取消已勾选的数据，外部列表数据同时也改变）
      */
      this.list = this.uniqueArr(this.list, this.modalConfig.selection, 'clientCode');
      this.modalConfig.checkRowKeys = [];
      this.modalConfig.selection = [];
      this.modalConfig.visible = false;
      this.emitChange();
    },
    emitChange() {
      this.$emit('input', this.list || []);
    },
  },
};
</script>
<style lang="less" scoped>
  .margin-bottom-10{
    margin-bottom: 10px;
  }
  .right{
    text-align: right;
  }
  /deep/ .page-pageination {
    text-align: right;
    padding: 10px 0;
    .el-input {
      display: inline-block !important;
    }
  }
</style>
