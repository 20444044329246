var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c(
            "el-row",
            { staticClass: "margin-bottom-10" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addRows } },
                [_vm._v("添加")]
              ),
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.deleteRows } },
                [_vm._v("删除")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: { data: _vm.list },
          on: {
            "checkbox-all": function (ref) {
              var records = ref.records

              return _vm.selectEvent(records)
            },
            "checkbox-change": function (ref) {
              var records = ref.records

              return _vm.selectEvent(records)
            },
          },
        },
        [
          !_vm.disabled
            ? _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "50" },
              })
            : _vm._e(),
          _c("vxe-table-column", {
            attrs: {
              title: "组织名称",
              field: "orgName",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              title: "职位名称",
              field: "positionName",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              title: "人员名称",
              field: "fullName",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.closeModal },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "请输入人员姓名模糊搜索",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.modalConfig.loading,
                        clearable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.getChange($event)
                        },
                        clear: _vm.getClear,
                      },
                      model: {
                        value: _vm.modalConfig.searchData,
                        callback: function ($$v) {
                          _vm.$set(_vm.modalConfig, "searchData", $$v)
                        },
                        expression: "modalConfig.searchData",
                      },
                    },
                    _vm._l(_vm.modalConfig.options, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label:
                            (item.orgName || "") +
                            "/" +
                            (item.positionName || "") +
                            "/" +
                            (item.fullName || ""),
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-body" },
            [
              _c(
                "vxe-table",
                {
                  ref: "xTable",
                  attrs: { border: "", data: _vm.modalConfig.data },
                  on: {
                    "checkbox-change": _vm.dialog_selectEvent,
                    "checkbox-all": _vm.dialog_selectEvent,
                  },
                },
                [
                  _c("vxe-table-column", {
                    attrs: { type: "checkbox", width: "50" },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "orgName",
                      title: "组织名称",
                      "min-width": "100",
                      "show-overflow": "",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "positionName",
                      title: "职位名称",
                      "min-width": "100",
                      "show-overflow": "",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "userName",
                      title: "人员编码",
                      "min-width": "100",
                      "show-overflow": "",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "fullName",
                      title: "人员姓名",
                      "min-width": "100",
                      "show-overflow": "",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: { title: "操作", "min-width": "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var rowIndex = ref.rowIndex
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.dialog_delTable(rowIndex)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }