var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c(
            "el-row",
            { staticClass: "margin-bottom-10" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addDelear } },
                [_vm._v("添加经销商")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addTerminal } },
                [_vm._v("添加终端")]
              ),
              _c(
                "el-button",
                { attrs: { plain: "" }, on: { click: _vm.deleteRows } },
                [_vm._v("删除")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: { data: _vm.list },
          on: {
            "checkbox-all": function (ref) {
              var records = ref.records

              return _vm.selectEvent(records)
            },
            "checkbox-change": function (ref) {
              var records = ref.records

              return _vm.selectEvent(records)
            },
          },
        },
        [
          !_vm.disabled
            ? _c("vxe-table-column", {
                attrs: { type: "checkbox", width: "50" },
              })
            : _vm._e(),
          _c("vxe-table-column", {
            attrs: {
              title: "客户编码",
              field: "clientCode",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              title: "客户名称",
              field: "clientName",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              title: "客户类型",
              field: "clientTypeName",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              title: "联系人",
              field: "clientContacts",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              title: "客户地址",
              field: "clientAddress",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.closeModal },
        },
        [
          _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                "row-id": "clientCode",
                data: _vm.modalConfig.data,
                "checkbox-config": {
                  checkRowKeys: _vm.modalConfig.checkRowKeys,
                  reserve: true,
                },
              },
              on: {
                "checkbox-all": function (ref) {
                  var records = ref.records

                  return _vm.dialog_selectEvent(records)
                },
                "checkbox-change": function (ref) {
                  var records = ref.records

                  return _vm.dialog_selectEvent(records)
                },
              },
            },
            [
              !_vm.disabled
                ? _c("vxe-table-column", {
                    attrs: { type: "checkbox", width: "50" },
                  })
                : _vm._e(),
              _c("vxe-table-column", {
                attrs: {
                  title: "客户编码",
                  field: "clientCode",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "客户名称",
                  field: "clientName",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "客户类型",
                  field: "clientTypeName",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "联系人",
                  field: "clientContacts",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
              _c("vxe-table-column", {
                attrs: {
                  title: "客户地址",
                  field: "clientAddress",
                  "min-width": "100",
                  "show-overflow": "",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page-pageination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.modalConfig.tablePage.currentPage,
                  "page-sizes": [5, 10, 15, 20, 50, 100, 150, 200],
                  "page-size": _vm.modalConfig.tablePage.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.modalConfig.tablePage.totalResult,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }