<script>
import TablePage from '../../../../../components/table_page';
import Form from './form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestUrl: '/sfa/sfaworktaskreceve/findSfaWorkTaskReportList',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Full',
      },
    };
  },
  async created() {
    const colums = await this.getConfigList('sfa_task_work_task');
    colums.map((v) => {
      const rowData = v;
      if ((rowData.field === 'publishPicture') || (rowData.field === 'executePicture')) {
        rowData.type = 'html';
        rowData.formatter = () => '查看';
      }
      return rowData;
    });
  },
  methods: {
    cellClick({ row, column }) {
      if (column.property === 'publishPicture') {
        this.formConfig = {
          id: row.id,
          type: 'publishPicture',
        };
        this.modalConfig.title = '任务发布照片';
        this.openFull();
      } else if (column.property === 'executePicture') {
        this.formConfig = {
          id: row.id,
          type: 'executePicture',
        };
        this.modalConfig.title = '任务执行照片';
        this.openFull();
      } else if (column.property === 'taskCode') {
        this.formConfig = {
          type: 'view',
          id: row.id,
          taskId: row.taskId,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
    modalClick({ val, row }) {
      this.formName = 'Form';
      this.formConfig = {};
      if (val.code === 'view') {
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      } else if (val.code === 'add') {
        this.formConfig = {
          type: val.code,
        };
        this.modalConfig.title = '新增';
        this.openFull();
      }
    },
  },
};
</script>
