<template>
  <div>
    <el-form>
      <template v-if="type === 'publishPicture' || type === 'view'">
        <div class="title">任务详情</div>
        <el-row :gutter="24">
          <el-col :md="8">
            <el-form-item label="任务标题" class="flex">
              <el-input readonly v-model="value.taskTitle"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="发布人" class="flex">
              <el-input readonly v-model="value.senderName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="发布日期" class="flex">
              <el-input readonly v-model="value.releaseDate"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="24">
            <el-form-item class="flex" label="任务内容">
              <el-input type="textarea" readonly v-model="value.content"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="8">
            <el-form-item label="截止日期" class="flex">
              <el-input readonly v-model="value.endDate"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="任务总状态" class="flex">
              <el-input readonly v-model="value.taskStatusDesc"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="任务执行人" class="flex">
              <el-input readonly v-model="value.receverName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="fileImg">
          <el-col :md="24">
            <el-form-item class="flex" label="任务发布照片">
              <el-image
              :src="item"
              lazy
              @click.stop="handleClickItem"
              :preview-src-list="[item]"
              v-for="(item, index) in value.sendTaskPicList"
              :key="index"
            ></el-image>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <template v-if="type === 'executePicture' || type === 'view'">
        <div class="title">执行详情</div>
        <el-row :gutter="24">
          <el-col :md="8">
            <el-form-item label="任务执行状态" class="flex">
              <el-input readonly v-model="value.executeStatusDesc"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="执行人" class="flex">
              <el-input readonly v-model="value.receverName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="执行人账号" class="flex">
              <el-input readonly v-model="value.receverCode"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="8">
            <el-form-item label="执行人所在区域" class="flex">
              <el-input readonly v-model="value.receverOrgName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="执行人所在办事处" class="flex">
              <el-input readonly v-model="value.receverOrgName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="执行日期" class="flex">
              <el-input v-model="value.receveDate" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="8">
            <el-form-item label="任务执行定位" class="flex">
              <el-input readonly v-model="value.address"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :md="24">
            <el-form-item class="flex" label="任务执行总结">
              <el-input type="textarea" readonly v-model="value.summary"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24" class="fileImg">
          <el-col :md="24">
            <el-form-item class="flex" label="任务执行照片">
              <el-image
              :src="item"
              lazy
              @click.stop="handleClickItem"
              :preview-src-list="[item]"
              v-for="(item, index) in value.executeTaskPicList"
              :key="index"
            ></el-image>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <template v-if="type === 'view'">
        <div class="title">执行对象</div>
        <el-row :gutter="24">
          <vxe-table :data="objectDetail">
            <vxe-table-column field="customerCode" title="对象编码"></vxe-table-column>
            <vxe-table-column field="customerName" title="对象名称"></vxe-table-column>
            <vxe-table-column field="customerType" title="对象类型">
              <template #default="{ row }">
                <!-- 0 终端 1经销商 -->
                <span v-if="row.customerType==0">终端</span>
                <span v-if="row.customerType==1">经销商</span>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-row>
      </template>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    type: String,
    objectDetail: Array,
  },
  created() {
    this.$emit('input', this.value);
    this.$emit('input', this.objectDetail);
  },
  methods: {
    handleClickItem() {
      // 获取遮罩层dom
      this.$nextTick(() => {
        const domImageMask = document.querySelector('.el-image-viewer__mask');
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener('click', () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector('.el-image-viewer__close').click();
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
  .flex{
    display: flex;
  }
  .el-row{
    margin: 0!important;
  }
  /deep/ .el-form-item__label{
    width: 125px;
  }
  /deep/ .el-form-item__content{
    flex: 1;
  }
  /deep/ .el-textarea__inner{
    height: 80px;
  }
  .el-col{
    padding: 0!important;
  }
  .title{
    font-size: 18px;
    margin-bottom: 20px;
  }
  /deep/.el-image__inner ,
  /deep/ .el-image__error{
    width: 280px;
    height: 250px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  /deep/ .fileImg .el-form-item__content > div{
    width: auto;
  }
</style>
